<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :manual-pagination="true"
      pdf-content-width="98%"
      :html-to-pdf-options="{
        margin: 5,
        filename: 'รายงานงบทดลอง',
        html2canvas: {
          scale: 2
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: reportDisplaySetting.pdfOrientation
        }
      }"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-content
          :dataProp="trialBalanceReportData"
          :reportDisplaySettingProp="reportDisplaySetting"
          :displaySummaryAccountProp="displaySummaryAccount"
        ></pdf-content>
      </section>
    </vue-html2pdf>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

import service from '@/services/report/classify.js'
import { toastMixins } from '@/mixins'

import PdfContent from './PdfContent.vue'

export default {
  mixins: [toastMixins],
  props: {
    click: Boolean,
    trialBalanceReportDataProp: Object,
    reportDisplaySettingProp: Object
  },
  components: {
    VueHtml2pdf,
    PdfContent
  },
  data() {
    return {
      trialBalanceReportData: {},
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      displaySummaryAccount: false,
      errMsg: null
    }
  },
  watch: {
    async click() {
      if (this.click) {
        await this.fetchTrialBalanceReportData()
      }
    },
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    trialBalanceReportDataProp() {
      this.assignDataFromProp('trialBalanceReportDataProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('trialBalanceReportDataProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = {
              ...this.reportDisplaySettingProp
            }
          }
          break
        case 'trialBalanceReportDataProp':
          const { displaySummaryAccount } = this.trialBalanceReportDataProp
          this.displaySummaryAccount = !!displaySummaryAccount
          break
      }
    },
    async fetchTrialBalanceReportData() {
      const body = this.trialBalanceReportDataProp
      try {
        const res = await service.postGenerateTrialBalanceReport(body)
        this.trialBalanceReportData = { ...res.data.data }

        // fetch report data success
        this.generateReport()
        this.$emit('reportGenerated')
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage ||
          'ดึงข้อมูลสำหรับออกรายงานงบทดลองไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    generateReport() {
      /**
        Generate Report using refs and calling the
        refs function generatePdf()
      */
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(e) {
      // console.log('onProgress');
      // console.log(e);
    },
    hasStartedGeneration() {
      // console.log('hasStartedGeneration');
    },
    hasGenerated(e) {
      // console.log('hasGenerated');
      // console.log(e);
    }
  }
}
</script>

<style></style>
