<template>
  <div>
    <div v-if="reportData.data.length <= 0">
      <PdfContentHeader
        :dataProp="reportData"
        :pageProp="1"
        :reportDisplaySettingProp="reportDisplaySetting"
        :totalPageCountProp="totalPageCount"
      ></PdfContentHeader>
      <PdfContentFooter
        :dataProp="reportData"
        :reportDisplaySettingProp="reportDisplaySetting"
      ></PdfContentFooter>
    </div>
    <div v-if="reportData.data.length > 0">
      <div v-for="(data, index) in reportData.data" :key="index">
        <PdfContentHeader
          v-if="index % pdfOrientationLimit === 0"
          :dataProp="reportData"
          :pageProp="index / pdfOrientationLimit + 1"
          :reportDisplaySettingProp="reportDisplaySetting"
          :totalPageCountProp="totalPageCount"
        ></PdfContentHeader>
        <PdfContentBody
          :dataProp="data"
          :reportDisplaySettingProp="reportDisplaySetting"
          :displaySummaryAccountProp="displaySummaryAccount"
        ></PdfContentBody>
        <PdfContentFooter
          v-if="index === reportData.data.length - 1"
          :dataProp="reportData"
          :reportDisplaySettingProp="reportDisplaySetting"
        ></PdfContentFooter>
        <div
          v-if="index % pdfOrientationLimit === pdfOrientationLimit - 1"
          class="html2pdf__page-break"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import PdfContentHeader from './PdfContentHeader.vue'
import PdfContentBody from './PdfContentBody.vue'
import PdfContentFooter from './PdfContentFooter.vue'

export default {
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object,
    displaySummaryAccountProp: Boolean
  },
  components: {
    PdfContentHeader,
    PdfContentBody,
    PdfContentFooter
  },
  data() {
    return {
      limit: 40,
      reportData: {
        date: null,
        companyName: null,
        fromDate: null,
        toDate: null,
        title: null,
        fromMonthIndex: null,
        toMonthIndex: null,
        data: [],
        finalBalance: {
          previousBalance: {
            debit: '0.00',
            credit: '0.00'
          },
          currentBalance: {
            debit: '0.00',
            credit: '0.00'
          },
          totalBalance: {
            debit: '0.00',
            credit: '0.00'
          }
        }
      },
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      displaySummaryAccount: false
    }
  },
  computed: {
    pdfOrientationLimit() {
      if (this.reportDisplaySetting.pdfOrientation === 'portrait') {
        return this.limit
      } else {
        return this.limit - 15
      }
    },
    totalPageCount() {
      return Math.ceil(this.reportData.data.length / this.pdfOrientationLimit)
    }
  },
  watch: {
    dataProp() {
      this.reportData = { ...this.dataProp }
    },
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    displaySummaryAccountProp() {
      this.assignDataFromProp('displaySummaryAccountProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('displaySummaryAccountProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
        case 'displaySummaryAccountProp':
          this.displaySummaryAccount = this.displaySummaryAccountProp
          break
      }
    }
  }
}
</script>

<style></style>
