<script>
import lodash from 'lodash'

import { numberMixins } from '@/mixins'

export default {
  mixins: [numberMixins],
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object,
    displaySummaryAccountProp: Boolean
  },
  data() {
    return {
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      dataObj: {
        accountId: null,
        accountName: null,
        accountType: null,
        previousBalance: {
          debit: null,
          credit: null
        },
        currentBalance: {
          debit: null,
          credit: null
        },
        totalBalance: {
          debit: null,
          credit: null
        }
      }
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    dataProp() {
      this.assignDataFromProp('dataProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('dataProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
        case 'dataProp':
          this.dataObj = { ...this.dataProp }
          break
      }
    },
    addIndent(word) {
      if (this.displaySummaryAccountProp === false) {
        return word
      } else {
        const { accountLevel } = this.dataProp
        const indent =
          (accountLevel - 1) * this.reportDisplaySetting.summaryAccountIndent
        const newWord =
          lodash
            .times(indent)
            .map(() => '\xa0')
            .join('') + word
        return newWord
      }
    }
  }
}
</script>

<template>
  <div
    :class="
      `text-sm d-flex ${dataObj.accountType === 'S' ? 'font-weight-bold' : ''}`
    "
  >
    <div :class="`account-id-col-${reportDisplaySetting.pdfOrientation}`">
      <span>{{ dataObj.accountId }}</span>
    </div>
    <div :class="`account-name-col-${reportDisplaySetting.pdfOrientation}`">
      <span>
        {{ addIndent(`${dataObj.accountName}`) }}
      </span>
    </div>
    <div
      :class="
        `account-type-col-${reportDisplaySetting.pdfOrientation} text-center`
      "
    >
      <span>{{ dataObj.accountType }}</span>
    </div>
    <div
      :class="
        `previous-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
      "
    >
      <span>
        {{
          dataObj.previousBalance.debit
            ? mxNumeralThousandSeperate(dataObj.previousBalance.debit)
            : '0.00'
        }}
      </span>
    </div>
    <div
      :class="
        `previous-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
      "
    >
      <span>
        {{
          dataObj.previousBalance.credit
            ? mxNumeralThousandSeperate(dataObj.previousBalance.credit)
            : '0.00'
        }}
      </span>
    </div>
    <div
      :class="
        `current-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
      "
    >
      <span>
        {{
          dataObj.currentBalance.debit
            ? mxNumeralThousandSeperate(dataObj.currentBalance.debit)
            : '0.00'
        }}
      </span>
    </div>
    <div
      :class="
        `current-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
      "
    >
      <span>
        {{
          dataObj.currentBalance.credit
            ? mxNumeralThousandSeperate(dataObj.currentBalance.credit)
            : '0.00'
        }}
      </span>
    </div>
    <div
      :class="
        `total-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
      "
    >
      <span>
        {{
          dataObj.totalBalance.debit
            ? mxNumeralThousandSeperate(dataObj.totalBalance.debit)
            : ''
        }}
      </span>
    </div>
    <div
      :class="
        `total-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
      "
    >
      <span>
        {{
          dataObj.totalBalance.credit
            ? mxNumeralThousandSeperate(dataObj.totalBalance.credit)
            : ''
        }}
      </span>
    </div>
  </div>
</template>

<style scoped>
/* Portrait */
.account-id-col-portrait {
  width: 8%;
}
.account-name-col-portrait {
  width: 13%;
  white-space: nowrap;
  overflow: hidden;
}
.account-type-col-portrait {
  width: 4%;
}
.previous-balance-col-portrait {
  width: 12.5%;
}
.current-balance-col-portrait {
  width: 12.5%;
}
.total-balance-col-portrait {
  width: 12.5%;
}
/* Landscape */
.account-id-col-landscape {
  width: 11%;
}
.account-name-col-landscape {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
}
.account-type-col-landscape {
  width: 5%;
}
.previous-balance-col-landscape {
  width: 18%;
}
.current-balance-col-landscape {
  width: 18%;
}
.total-balance-col-landscape {
  width: 18%;
}
</style>
