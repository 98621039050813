<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import { datetimeMixins, toastMixins } from '@/mixins'
import service from '@/services/report/classify.js'

import ExportPdf from './ExportPdf.vue'

export default {
  mixins: [datetimeMixins, toastMixins],
  components: { ExportPdf },
  props: {
    shouldUpdateReportHistoryProp: Boolean
  },
  data() {
    return {
      fields: [
        { key: 'no', label: 'ลำดับที่' },
        { key: 'requestType', label: 'ประเภท' },
        { key: 'fromAccount', label: 'พิมพ์จาก' },
        { key: 'toAccount', label: 'พิมพ์ถึง' },
        { key: 'accountIds', label: 'จำนวนรหัส' },
        { key: 'startDate', label: 'จากวันที่' },
        { key: 'endDate', label: 'ถึงวันที่' },
        { key: 'remark', label: 'หมายเหตุ' },
        { key: 'specificDetailOnCreditDebit', label: 'รายละเอียด' },
        { key: 'displayUnavailableAccount', label: 'บ/ช ไม่เคลื่อนไหว' },
        { key: 'displaySummaryAccount', label: 'บ/ช สรุปยอด' },
        { key: 'generateSubTotal', label: 'ออกยอดรวมย่อย' },
        { key: 'createdAt', label: 'สร้างเมื่อ' },
        { key: 'downloadUseDelete', label: '' }
      ],
      originalReportHistory: [],
      reportHistory: [],
      startExportPdf: false,
      isDeleteLoading: false,
      trialBalanceReportData: {},
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  watch: {
    shouldUpdateReportHistoryProp() {
      if (this.shouldUpdateReportHistoryProp) {
        this.fetchReportHistories()
        this.$emit('updateReportHistorySuccessful')
      }
    }
  },
  created() {
    this.fetchReportHistories()
  },
  methods: {
    async fetchReportHistories() {
      try {
        const res = await service.getTrialBalanceReportHistories()
        this.originalReportHistory = [...res.data.data]
        this.reportHistory = res.data.data.map((el, index) =>
          this.reformatFields(el, index + 1)
        )
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage ||
          'ดึงข้อมูลประวัติการออกรายงานงบทดลองไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    reformatFields(report, index) {
      const {
        id,
        requestType,
        fromAccount,
        toAccount,
        accountIds,
        fromDate,
        toDate,
        remark,
        specificDetailOnCreditDebit,
        displayUnavailableAccount,
        displaySummaryAccount,
        generateSubTotal,
        createdAt
      } = report

      return {
        no: index,
        historyId: id,
        requestType: requestType,
        fromAccount: fromAccount,
        toAccount: toAccount,
        accountIds: JSON.parse(accountIds),
        startDate: fromDate,
        endDate: toDate,
        remark: remark,
        specificDetailOnCreditDebit: specificDetailOnCreditDebit,
        displayUnavailableAccount: displayUnavailableAccount,
        displaySummaryAccount: displaySummaryAccount,
        generateSubTotal: generateSubTotal,
        createdAt: createdAt
      }
    },
    reportGeneratedHandler() {
      this.startExportPdf = false
    },
    downloadButtonClicked(historyId) {
      const el = this.originalReportHistory.find(
        report => report.id === historyId
      )
      this.trialBalanceReportData = {
        requestType: el.requestType,
        fromAccount: _.isEqual(el.requestType, 'ACCOUNT_INTERVAL')
          ? el.fromAccount
          : undefined,
        toAccount: _.isEqual(el.requestType, 'ACCOUNT_INTERVAL')
          ? el.toAccount
          : undefined,
        accountIds: _.isEqual(el.requestType, 'ACCOUNT_SPECIFIC')
          ? JSON.parse(el.accountIds)
          : undefined,
        fromDate: el.fromDate,
        toDate: el.toDate,
        specificDetailOnCreditDebit: el.specificDetailOnCreditDebit,
        remark: el.remark,
        displayUnavailableAccount: el.displayUnavailableAccount,
        displaySummaryAccount: el.displaySummaryAccount,
        generateSubTotal: el.generateSubTotal,
        saveReportSetting: false
      }
      this.startExportPdf = true
    },
    applyButtonClicked(historyId) {
      const el = this.originalReportHistory.find(
        report => report.id === historyId
      )
      const reformatEl = {}
      Object.keys(el).forEach(key => {
        reformatEl[key] = el[key]
      })
      this.$emit('applyReportData', reformatEl)
    },
    async deleteButtonClicked(historyId) {
      this.isDeleteLoading = true
      try {
        await service.deleteTrialBalanceReportHistoryById(historyId)
        await this.fetchReportHistories()
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage ||
          'ลบประวัติการออกรายงานงบทดลองไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isDeleteLoading = false
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12" class="bg-light p-4" style="border-radius: 10px;">
      <div>
        <!-- topic -->
        <div>
          <span class="text-lg font-weight-bold">ประวัติการออกรายงาน</span>
        </div>

        <!-- table -->
        <div class="mt-3 overflow-auto">
          <b-table
            striped
            hover
            bordered
            small
            :fields="fields"
            :items="reportHistory"
            :head-variant="'dark'"
            :sticky-header="true"
            class="text-sm"
          >
            <!-- table header -->
            <template #head(no)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(requestType)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(fromAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(toAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(accountIds)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(startDate)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(endDate)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(remark)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(displayUnavailableAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(displaySummaryAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(generateSubTotal)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(createdAt)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(downloadAndDelete)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <!-- table body -->
            <template #cell(no)="data">
              {{ data.item.no }}
            </template>
            <template #cell(requestType)="data">
              {{
                data.item.requestType === 'ACCOUNT_SPECIFIC' ? 'เลือก' : 'ช่วง'
              }}
            </template>
            <template #cell(fromAccount)="data">
              {{ data.item.fromAccount || '-' }}
            </template>
            <template #cell(toAccount)="data">
              {{ data.item.toAccount || '-' }}
            </template>
            <template #cell(accountIds)="data">
              {{ data.item.accountIds ? data.item.accountIds.length : '-' }}
            </template>
            <template #cell(startDate)="data">
              {{ data.item.startDate }}
            </template>
            <template #cell(endDate)="data">
              {{ data.item.endDate }}
            </template>
            <template #cell(remark)="data">
              {{ data.item.remark || '-' }}
            </template>
            <template #cell(specificDetailOnCreditDebit)="data">
              {{ data.item.specificDetailOnCreditDebit ? 'ใช่' : '-' }}
            </template>
            <template #cell(displayUnavailableAccount)="data">
              {{ data.item.displayUnavailableAccount ? 'ใช่' : '-' }}
            </template>
            <template #cell(displaySummaryAccount)="data">
              {{ data.item.displaySummaryAccount ? 'ใช่' : '-' }}
            </template>
            <template #cell(generateSubTotal)="data">
              {{ data.item.generateSubTotal ? 'ใช่' : '-' }}
            </template>
            <template #cell(createdAt)="data">
              {{ mxConvertToDatetimeBuddhistFormat(data.item.createdAt) }}
            </template>
            <template #cell(downloadUseDelete)="data">
              <div class="d-flex justify-content-center">
                <div class="mr-2">
                  <b-button
                    v-if="user && user.permission.reportTrialBalance.canPrint"
                    variant="success"
                    size="sm"
                    @click="downloadButtonClicked(data.item.historyId)"
                    :disabled="startExportPdf || isDeleteLoading"
                  >
                    <b-icon icon="download" scale="0.8"></b-icon>
                  </b-button>
                </div>
                <div class="mr-2">
                  <b-button
                    v-if="user && user.permission.reportTrialBalance.canPrint"
                    variant="primary"
                    size="sm"
                    :disabled="startExportPdf || isDeleteLoading"
                  >
                    <b-icon
                      icon="box-arrow-up"
                      scale="0.8"
                      @click="applyButtonClicked(data.item.historyId)"
                    ></b-icon>
                  </b-button>
                </div>
                <div>
                  <b-button
                    v-if="user && user.permission.reportTrialBalance.canDelete"
                    variant="danger"
                    size="sm"
                    @click="deleteButtonClicked(data.item.historyId)"
                    :disabled="startExportPdf || isDeleteLoading"
                  >
                    <b-icon icon="trash" scale="0.8"></b-icon>
                  </b-button>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-col>

    <!-- export pdf -->
    <b-col class="d-none">
      <b-col>
        <export-pdf
          :click="startExportPdf"
          :trialBalanceReportDataProp="trialBalanceReportData"
          @reportGenerated="reportGeneratedHandler"
        ></export-pdf>
      </b-col>
    </b-col>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-row>
</template>

<style scoped>
.header-column-style {
  text-align: center;
}
</style>
