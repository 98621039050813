import client from './../api.js'

const endpoint = '/master/ge1mast'

export default {
  getTypeOptions: async () => {
    return await client.get(`${endpoint}/options/ledger-type-choice`)
  },
  getLevelOptions: async () => {
    return await client.get(`${endpoint}/options/ledger-level-choice`)
  },
  getCategoryOptions: async () => {
    return await client.get(`${endpoint}/options/ledger-category-choice`)
  },
  getGe1mastTables: async () => {
    return await client.get(`${endpoint}/table`)
  },
  getGeneralLedgerMasterAccounts: async (querystring = null) => {
    return await client.get(
      `${endpoint}/general-ledger-master-accounts${
        querystring ? '?' + querystring.join('&') : ''
      }`
    )
  },
  getAllGeneralLedgerMasterAccounts: async (querystring = null) => {
    return await client.get(
      `${endpoint}/general-ledger-master-accounts/all${
        querystring ? '?' + querystring.join('&') : ''
      }`
    )
  },
  getGeneralLedgerMasterAccountByAccountNumber: async accountNumber => {
    return await client.get(
      `${endpoint}/general-ledger-master-accounts/account-number/${accountNumber}`
    )
  },
  createGe1mast: async body => {
    return await client.post(`${endpoint}`, body)
  },
  updateGe1mastById: async body => {
    return await client.put(`${endpoint}`, body)
  },
  deleteGe1mastById: async body => {
    return await client.put(`${endpoint}/delete`, body)
  },
  getDeleteGe1mastPermissionById: async id => {
    return await client.get(`${endpoint}/${id}/delete-permission`)
  },
  getExportPdfData: async () => {
    return await client.get(`${endpoint}/export-pdf-data`)
  },
  getGe1mastTableById: async id => {
    return await client.get(`${endpoint}/${id}/table`)
  },
  getForbiddenLedgerAccountLinkers: async () => {
    return await client.get(`${endpoint}/forbidden-ledger-account-linkers`)
  }
}
