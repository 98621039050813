<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import ExportPdf from './ExportPdf.vue'
import ReportDisplaySettingModal from './ReportDisplaySettingModal.vue'
import SelectAccountModal from '@/components/modal/SelectAccountModal.vue'
import SelectAccountsModal from '@/components/modal/SelectAccountsModal.vue'

export default {
  components: {
    ExportPdf,
    ReportDisplaySettingModal,
    SelectAccountModal,
    SelectAccountsModal
  },
  props: {
    reportDataProp: Object
  },
  data() {
    return {
      monthOptions: [
        { value: '01', text: '01' },
        { value: '02', text: '02' },
        { value: '03', text: '03' },
        { value: '04', text: '04' },
        { value: '05', text: '05' },
        { value: '06', text: '06' },
        { value: '07', text: '07' },
        { value: '08', text: '08' },
        { value: '09', text: '09' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' }
      ],
      yearOptions: [],
      requestType: 'ACCOUNT_INTERVAL',
      requestTypeOptions: [
        { text: 'ช่วง', value: 'ACCOUNT_INTERVAL' },
        { text: 'เลือก', value: 'ACCOUNT_SPECIFIC' }
      ],
      fromAccount: null,
      toAccount: null,
      startMonth: '01',
      startYear: null,
      endMonth: '01',
      endYear: null,
      accountSelectedIds: [],
      specificDetailOnCreditDebit: false,
      remark: null,
      displayUnavailableAccount: false,
      displaySummaryAccount: false,
      generateSubTotal: false,
      saveReportSetting: false,
      // export pdf component
      exportPdfComponentClick: false,
      trialBalanceReportData: {},
      // report display setting modal component
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      reportSettingDisplayModal: false,
      // select account modal component
      selectAccountModalComponentDisplayModal: false,
      selectAccountModalComponentAccount: {
        key: null,
        accountNumber: null
      },
      // select accounts modal component
      selectAccountsModalComponentDisplayModal: false
    }
  },
  computed: {
    ...mapState({
      stateAuthCompany: (state) => state.auth.company
    })
  },
  watch: {
    reportDataProp() {
      if (!_.isEmpty(this.reportDataProp)) {
        const {
          requestType,
          fromAccount,
          toAccount,
          accountIds,
          fromDate,
          toDate,
          remark,
          specificDetailOnCreditDebit,
          displayUnavailableAccount,
          displaySummaryAccount,
          generateSubTotal
        } = this.reportDataProp

        this.requestType = requestType
        this.fromAccount = _.isEqual(requestType, 'ACCOUNT_INTERVAL')
          ? fromAccount
          : null
        this.toAccount = _.isEqual(requestType, 'ACCOUNT_INTERVAL')
          ? toAccount
          : null
        this.accountSelectedIds = _.isEqual(requestType, 'ACCOUNT_SPECIFIC')
          ? JSON.parse(accountIds)
          : []
        this.startMonth = fromDate.split('/')[0]
        this.startYear = fromDate.split('/')[1]
        this.endMonth = toDate.split('/')[0]
        this.endYear = toDate.split('/')[1]
        this.specificDetailOnCreditDebit = specificDetailOnCreditDebit
        this.remark = remark
        this.displayUnavailableAccount = displayUnavailableAccount
        this.displaySummaryAccount = displaySummaryAccount
        this.generateSubTotal = generateSubTotal
        this.saveReportSetting = false
      }
    }
  },
  created() {
    this.initailizeYearOptions()
    this.initializeDate()
  },
  methods: {
    initailizeYearOptions() {
      if (this.stateAuthCompany) {
        const { programStartDate, programEndDate } = this.stateAuthCompany
        this.yearOptions = [programStartDate, programEndDate].map((el) => {
          const [year] = el.split('-')
          const buddhistYear = `${Number(year) + 543}`
          return { text: buddhistYear, value: buddhistYear }
        })
      }
    },
    initializeDate() {
      if (this.stateAuthCompany) {
        const { programStartDate } = this.stateAuthCompany
        const [year] = programStartDate.split('-')
        const buddhistYear = `${Number(year) + 543}`
        this.startYear = buddhistYear
        this.endYear = buddhistYear
      }
    },
    generateReportButtonClicked() {
      if (_.isEqual(this.requestType, 'ACCOUNT_INTERVAL')) {
        this.trialBalanceReportData = {
          requestType: 'ACCOUNT_INTERVAL',
          fromAccount:
            this.fromAccount && this.fromAccount.trim().length > 0
              ? this.fromAccount.trim()
              : null,
          toAccount:
            this.toAccount && this.toAccount.trim().length > 0
              ? this.toAccount.trim()
              : null,
          fromDate: `${this.startMonth}/${this.startYear}`,
          toDate: `${this.endMonth}/${this.endYear}`,
          specificDetailOnCreditDebit: this.specificDetailOnCreditDebit,
          remark:
            this.remark && this.remark.trim().length > 0
              ? this.remark.trim()
              : null,
          displayUnavailableAccount: this.displayUnavailableAccount,
          displaySummaryAccount: this.displaySummaryAccount,
          generateSubTotal: this.generateSubTotal,
          saveReportSetting: this.saveReportSetting
        }
      } else if (_.isEqual(this.requestType, 'ACCOUNT_SPECIFIC')) {
        this.trialBalanceReportData = {
          requestType: 'ACCOUNT_SPECIFIC',
          accountIds: [...this.accountSelectedIds],
          fromDate: `${this.startMonth}/${this.startYear}`,
          toDate: `${this.endMonth}/${this.endYear}`,
          specificDetailOnCreditDebit: this.specificDetailOnCreditDebit,
          remark:
            this.remark && this.remark.trim().length > 0
              ? this.remark.trim()
              : null,
          displayUnavailableAccount: true,
          displaySummaryAccount: false,
          generateSubTotal: this.generateSubTotal,
          saveReportSetting: this.saveReportSetting
        }
      }

      this.exportPdfComponentClick = true
    },
    tableIconClicked(key) {
      switch (key) {
        case 'fromAccount':
          this.selectAccountModalComponentDisplayModal = true
          this.selectAccountModalComponentAccount = {
            key: 'fromAccount',
            accountNumber: this.fromAccount
          }
          break
        case 'toAccount':
          this.selectAccountModalComponentDisplayModal = true
          this.selectAccountModalComponentAccount = {
            key: 'toAccount',
            accountNumber: this.toAccount
          }
          break
        default:
          break
      }
    },
    // export pdf component
    exportPdfComponentReportGeneratedHandler() {
      this.exportPdfComponentClick = false

      if (this.saveReportSetting) {
        this.$emit('generateReportButtonClicked')
      }
    },
    // report display setting modal component
    reportDisplaySettingModalComponentModalClosedHandler(payload) {
      if (!_.isUndefined(payload)) {
        this.reportDisplaySetting = { ...payload }
      }
      this.reportSettingDisplayModal = false
    },
    // select account modal component
    selectAccountModalComponentAccountSelectedHandler(payload) {
      const { accountNumber } = payload
      switch (this.selectAccountModalComponentAccount.key) {
        case 'fromAccount':
          this.fromAccount = accountNumber
          break
        case 'toAccount':
          this.toAccount = accountNumber
          break
        default:
          break
      }
    },
    // select accounts modal component
    selectAccountsModalComponentAccountsSelectedHandler(payload) {
      this.accountSelectedIds = payload.map((acc) => acc.accountNumber)
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12" class="bg-light p-4" style="border-radius: 10px">
      <!-- topic -->
      <b-row>
        <b-col>
          <span class="text-lg font-weight-bold">ตั้งค่าออกรายงาน</span>
        </b-col>
      </b-row>

      <!-- table -->
      <b-row>
        <!-- เลขบัญชี / ช่วงเวลา -->
        <b-col class="m-0 p-0 px-3" cols="12" lg="6">
          <div>
            <div class="d-flex">
              <div class="d-flex align-items-center my-3 mr-2">
                <b-form-select
                  v-model="requestType"
                  :options="requestTypeOptions"
                  size="sm"
                  :disabled="exportPdfComponentClick"
                ></b-form-select>
              </div>

              <!-- เลขบัญชี -->
              <div
                v-if="requestType === 'ACCOUNT_INTERVAL'"
                class="d-flex align-items-center my-3"
              >
                <span class="mr-2">พิมพ์เลขบัญชี จาก</span>
                <b-input
                  class="mr-2 input-field-fixed-width-size"
                  placeholder="แรกสุด"
                  v-model="fromAccount"
                  :disabled="exportPdfComponentClick"
                ></b-input>
                <b-icon
                  icon="table"
                  class="cursor-pointer mr-2"
                  @click="tableIconClicked('fromAccount')"
                ></b-icon>
                <span class="mr-2">ถึง</span>
                <b-input
                  class="mr-2 input-field-fixed-width-size"
                  placeholder="ท้ายสุด"
                  v-model="toAccount"
                  :disabled="exportPdfComponentClick"
                ></b-input>
                <b-icon
                  icon="table"
                  class="cursor-pointer"
                  @click="tableIconClicked('toAccount')"
                ></b-icon>
              </div>
              <div
                v-if="requestType === 'ACCOUNT_SPECIFIC'"
                class="d-flex align-items-center my-3"
              >
                <b-button
                  variant="primary"
                  class="ml-2 px-3"
                  size="sm"
                  @click="selectAccountsModalComponentDisplayModal = true"
                  :disabled="exportPdfComponentClick"
                >
                  เลือกเฉพาะเลขบัญชี
                </b-button>
              </div>
            </div>

            <!-- ช่วงเวลา -->
            <div class="d-flex align-items-center">
              <span class="mr-2">ในช่วงวันที่ จาก</span>
              <div class="mr-2">
                <div class="d-flex align-items-center">
                  <b-select
                    class="mr-2 select-field-fixed-small-width-size"
                    v-model="startMonth"
                    :options="monthOptions"
                    :disabled="exportPdfComponentClick"
                  ></b-select>
                  <span class="mr-2">/</span>
                  <b-select
                    class="select-field-fixed-large-width-size"
                    v-model="startYear"
                    :options="yearOptions"
                    :disabled="exportPdfComponentClick"
                  ></b-select>
                </div>
              </div>
              <span class="mr-2">ถึง</span>
              <div class="mr-2">
                <div class="d-flex align-items-center">
                  <b-select
                    class="mr-2 select-field-fixed-small-width-size"
                    v-model="endMonth"
                    :options="monthOptions"
                    :disabled="exportPdfComponentClick"
                  ></b-select>
                  <span class="mr-2">/</span>
                  <b-select
                    class="select-field-fixed-large-width-size"
                    v-model="endYear"
                    :options="yearOptions"
                    :disabled="exportPdfComponentClick"
                  ></b-select>
                </div>
              </div>
            </div>

            <!-- งวด -->
            <div class="mt-4 text-sm">
              <div>
                <b-form-checkbox
                  v-model="saveReportSetting"
                  :disabled="exportPdfComponentClick"
                >
                  บันทึกการตั้งค่าออกรายงานนี้
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-col>

        <!-- หมายเหตุ / เงื่อนไข -->
        <b-col class="m-0 p-0 px-3" cols="12" lg="6">
          <div>
            <!-- หมายเหตุ -->
            <div class="my-3 d-flex align-items-center">
              <span class="mr-3">หมายเหตุ</span>
              <b-input
                placeholder="หมายเหตุ"
                v-model="remark"
                :disabled="exportPdfComponentClick"
              ></b-input>
            </div>

            <!-- เงื่อนไข -->
            <div>
              <!-- แสดงรายละเอียดยอดรวมของงวด -->
              <div class="d-flex mt-1">
                <div style="width: 250px">
                  <span>แสดงรายละเอียดยอดรวมของงวด</span>
                </div>
                <div class="d-flex">
                  <b-form-radio
                    class="mr-5"
                    v-model="specificDetailOnCreditDebit"
                    :value="true"
                    :disabled="exportPdfComponentClick"
                  >
                    ใช่
                  </b-form-radio>
                  <b-form-radio
                    v-model="specificDetailOnCreditDebit"
                    :value="false"
                    :disabled="exportPdfComponentClick"
                  >
                    ไม่ใช่
                  </b-form-radio>
                </div>
              </div>

              <!-- บ/ช ไม่เคลื่อนไหว -->
              <div
                v-if="requestType !== 'ACCOUNT_SPECIFIC'"
                class="d-flex mt-1"
              >
                <div style="width: 250px">
                  <span>แสดงบัญชีไม่เคลื่อนไหว</span>
                </div>
                <div class="d-flex">
                  <b-form-radio
                    class="mr-5"
                    v-model="displayUnavailableAccount"
                    :value="true"
                    :disabled="exportPdfComponentClick"
                  >
                    ใช่
                  </b-form-radio>
                  <b-form-radio
                    v-model="displayUnavailableAccount"
                    :value="false"
                    :disabled="exportPdfComponentClick"
                  >
                    ไม่ใช่
                  </b-form-radio>
                </div>
              </div>

              <!-- บ/ช สรุปยอด -->
              <div
                v-if="requestType !== 'ACCOUNT_SPECIFIC'"
                class="d-flex mt-1"
              >
                <div style="width: 250px">
                  <span>แสดงบัญชีสรุปยอด</span>
                </div>
                <div class="d-flex">
                  <b-form-radio
                    class="mr-5"
                    v-model="displaySummaryAccount"
                    :value="true"
                    :disabled="exportPdfComponentClick"
                  >
                    ใช่
                  </b-form-radio>
                  <b-form-radio
                    v-model="displaySummaryAccount"
                    :value="false"
                    :disabled="exportPdfComponentClick"
                  >
                    ไม่ใช่
                  </b-form-radio>
                </div>
              </div>

              <!-- ออกยอดรวมย่อย -->
              <div class="d-flex mt-1">
                <div style="width: 250px">
                  <span>ออกยอดรวมย่อย</span>
                </div>
                <div class="d-flex">
                  <b-form-radio
                    class="mr-5"
                    v-model="generateSubTotal"
                    :value="true"
                    :disabled="exportPdfComponentClick"
                  >
                    ใช่
                  </b-form-radio>
                  <b-form-radio
                    v-model="generateSubTotal"
                    :value="false"
                    :disabled="exportPdfComponentClick"
                  >
                    ไม่ใช่
                  </b-form-radio>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- hover line -->
      <hr />

      <!-- buttons -->
      <b-row>
        <b-col class="m-0 p-0">
          <div class="d-flex justify-content-start justify-content-lg-end">
            <b-button
              variant="primary"
              @click="generateReportButtonClicked()"
              :disabled="exportPdfComponentClick"
            >
              <b-spinner
                v-if="exportPdfComponentClick"
                variant="light"
                small
              ></b-spinner>
              <span v-else>ออกรายงาน</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="mx-3"
              @click="reportSettingDisplayModal = true"
              :disabled="exportPdfComponentClick"
            >
              ตั้งค่าการแสดงผล
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- export pdf -->
      <b-row class="d-none">
        <b-col>
          <export-pdf
            :click="exportPdfComponentClick"
            :reportDisplaySettingProp="reportDisplaySetting"
            :trialBalanceReportDataProp="trialBalanceReportData"
            @reportGenerated="exportPdfComponentReportGeneratedHandler"
          ></export-pdf>
        </b-col>
      </b-row>

      <!-- report display setting modal -->
      <b-row>
        <b-col>
          <report-display-setting-modal
            :reportDisplaySettingProp="reportDisplaySetting"
            :modalShowProp="reportSettingDisplayModal"
            @modalClosed="reportDisplaySettingModalComponentModalClosedHandler"
          ></report-display-setting-modal>
        </b-col>
      </b-row>

      <!-- select account modal -->
      <b-row>
        <b-col>
          <select-account-modal
            :displayModalProp="selectAccountModalComponentDisplayModal"
            :accountProp="selectAccountModalComponentAccount"
            @modalClosed="selectAccountModalComponentDisplayModal = false"
            @accountSelected="selectAccountModalComponentAccountSelectedHandler"
          ></select-account-modal>
        </b-col>
      </b-row>

      <!-- select accounts modal -->
      <b-row>
        <b-col>
          <select-accounts-modal
            :accountSelectedIdsProp="accountSelectedIds"
            :displayModalProp="selectAccountsModalComponentDisplayModal"
            :summaryAccountAvailableProp="false"
            @modalClosed="selectAccountsModalComponentDisplayModal = false"
            @accountsSelected="
              selectAccountsModalComponentAccountsSelectedHandler
            "
          ></select-accounts-modal>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<style scoped>
.input-field-fixed-width-size {
  width: 100px;
}
.select-field-fixed-small-width-size {
  width: 60px;
}
.select-field-fixed-large-width-size {
  width: 80px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
