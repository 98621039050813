<script>
/*
Props: {
  displayModalProp: เปิดปิดการแสดง modal (type: boolean)
  accountProp: บัญชี (type: { accountNumber?: string })
}
Events: {
  modalClosed: เมื่อ modal ถูกปิด (payload: undefined)
  accountSelected: เมื่อเลือกบัญชีได้แล้ว (payload: {
    id: number
    accountNumber: string
    accountName: string
    accountType: string
    accountTypeMessage: string 
    parentAccount: string | null
    rcsi: boolean
    rcsiMessage: string
    accountLevel: number
    group: string
    groupMessage: string 
    consolidateAccount: string | null
  })
}
*/

import _ from 'lodash'
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'
import ge1mastService from '@/services/master/ge1mast.js'

export default {
  mixins: [toastMixins],
  props: {
    displayModalProp: Boolean,
    accountProp: Object
  },
  data() {
    return {
      displayModal: false,
      tableOriginalHeaders: [
        {
          key: 'accountNumber',
          attr: 'glmaccn_accountNumber',
          label: 'เลขที่บัญชี'
        },
        { key: 'accountName', attr: 'glmaccn_accountName', label: 'ชื่อบัญชี' },
        {
          key: 'accountTypeMessage',
          attr: 'glmaccn_accountTypeMessage',
          label: 'ชนิด'
        },
        {
          key: 'parentAccount',
          attr: 'glmaccn_parentAccount',
          label: 'บัญชีแม่'
        },
        {
          key: 'rcsiMessage',
          attr: 'glmaccn_rcsiMessage',
          label: 'เป็น'
        },
        { key: 'accountLevel', attr: 'glmaccn_accountLevel', label: 'ระดับ' },
        { key: 'groupMessage', attr: 'glmaccn_groupMessage', label: 'หมวด' },
        {
          key: 'consolidateAccount',
          attr: 'glmaccn_consolidateAccount',
          label: 'บัญชีกลาง'
        }
      ],
      tableHeaders: [],
      sortTypeOptions: [
        { value: 'desc', text: 'เรียงแบบ ท้ายสุด - แรกสุด' },
        { value: 'asc', text: 'เรียงแบบ แรกสุด - ท้ายสุด' }
      ],
      sortByOptions: [
        { value: 'glmaccn_accountNumber', text: 'เรียงตาม เลขที่บัญชี' }
      ],
      searchByOptions: [
        { value: 'glmaccn_accountNumber', text: 'เลขที่บัญชี' },
        { value: 'glmaccn_accountName', text: 'ชื่อบัญชี' }
      ],
      tableContents: [],
      isLoading: false,
      searchKeyword: '',
      searchKeywordTimer: null,
      rowSelected: null,
      page: 1,
      limit: 20,
      sortType: 'asc',
      sortBy: 'glmaccn_accountNumber',
      searchBy: 'glmaccn_accountNumber',
      first: false,
      prev: false,
      next: false,
      last: false,
      forbiddenLedgerAccountLinkers: [],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      stateConstantsDefaultKeywordTimerMs: state =>
        state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    displayModalProp() {
      this.displayModal = this.displayModalProp
      if (this.displayModal) {
        this.searchKeyword =
          this.accountProp && this.accountProp.accountNumber
            ? this.accountProp.accountNumber
            : ''
        this.fetchGeneralLedgerMasterAccounts()
      } else {
        this.resetModalData()
      }
    },
    displayModal() {
      if (!this.displayModal) {
        this.$emit('modalClosed')
      }
    },
    sortType() {
      this.first = false
      this.prev = false
      this.next = false
      this.last = false
      this.page = 1
      this.fetchGeneralLedgerMasterAccounts()
    },
    sortBy() {
      this.first = false
      this.prev = false
      this.next = false
      this.last = false
      this.page = 1
      this.editTableHeadersWhenSortByChanged(this.sortBy)
      this.fetchGeneralLedgerMasterAccounts()
    },
    searchKeyword() {
      if (this.searchKeywordTimer) {
        clearTimeout(this.searchKeywordTimer)
      }
      const timer = setTimeout(() => {
        this.first = false
        this.prev = false
        this.next = false
        this.last = false
        this.page = 1
        this.fetchGeneralLedgerMasterAccounts()
        this.searchKeywordTimer = null
      }, this.stateConstantsDefaultKeywordTimerMs)
      this.searchKeywordTimer = timer
    },
    searchBy() {
      this.first = false
      this.prev = false
      this.next = false
      this.last = false
      this.page = 1
      this.fetchGeneralLedgerMasterAccounts()
    }
  },
  async created() {
    await this.initialize()
  },
  methods: {
    async initialize() {
      this.isLoading = true
      this.tableHeaders = this.tableOriginalHeaders.map(el => ({ ...el }))
      await this.fetchGeneralLedgerMasterAccounts()
      await this.fetchForbiddenLedgerAccountLinkers()
      this.isLoading = false
    },
    selectedButtonClicked() {
      let errors = this.validateSelectedAccount(this.rowSelected)
      if (errors.length > 0) {
        const errorMessage = errors.join(', ')
        this.errMsg = `ไม่สามารถเลือกบัญชี ${this.rowSelected.accountNumber} ได้เนื่องจาก ${errorMessage}`
      } else {
        this.displayModal = false
        this.$emit('accountSelected', this.rowSelected)
      }
    },
    async fetchGeneralLedgerMasterAccounts() {
      try {
        this.isLoading = true
        const querystrings = [
          `sortType=${this.sortType}`,
          `limit=${this.limit}`,
          `page=${this.page}`,
          `sortBy=${this.sortBy}`
        ]
        if (this.searchKeyword.trim().length > 0) {
          querystrings.push(`keyword=${this.searchKeyword.trim()}`)
          querystrings.push(`searchBy=${this.searchBy}`)
        }
        const res = await ge1mastService.getGeneralLedgerMasterAccounts(
          querystrings
        )
        const { first, prev, next, last, accounts } = res.data.data
        this.formatTableContents(accounts)
        this.first = first
        this.prev = prev
        this.next = next
        this.last = last
      } catch (err) {
        console.log(err)
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลผังบัญชีไม่สำเร็จ'
        this.errMsg = errMessage
      } finally {
        this.isLoading = false
      }
    },
    async fetchForbiddenLedgerAccountLinkers() {
      try {
        const res = await ge1mastService.getForbiddenLedgerAccountLinkers()
        this.forbiddenLedgerAccountLinkers = res.data.data
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลบัญชีแยกประเภทไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    formatTableContents(contents) {
      this.tableContents = contents.map(content => ({
        id: content.glmaccn_id,
        accountNumber: content.glmaccn_accountNumber,
        accountName: content.glmaccn_accountName,
        accountType: content.glmaccn_accountType,
        accountTypeMessage: content.glmaccn_accountTypeMessage,
        parentAccount: content.glmaccn_parentAccount,
        rcsi: content.glmaccn_rcsi,
        rcsiMessage: content.glmaccn_rcsi ? 'ธนาคาร' : '-',
        accountLevel: content.glmaccn_accountLevel,
        group: content.glmaccn_group,
        groupMessage: content.glmaccn_groupMessage,
        consolidateAccount: content.glmaccn_consolidateAccount
      }))
    },
    rowSelectedHandler(e) {
      if (e.length > 0) this.rowSelected = e[0]
      else this.rowSelected = null
    },
    resetModalData() {
      this.tableContents = []
      this.isLoading = false
      this.searchKeyword = ''
      this.rowSelected = null
      this.sortType = 'asc'
      this.sortBy = 'glmaccn_accountNumber'
    },
    paginationButtonClicked(page) {
      this.page = page
      this.fetchGeneralLedgerMasterAccounts()
    },
    rowDoubleClickedHandler(e) {
      this.rowSelected = e
      this.selectedButtonClicked()
    },
    editTableHeadersWhenSortByChanged(target) {
      const copiedTableHeaders = this.tableOriginalHeaders.map(el => ({
        ...el
      }))
      const sortByObject = copiedTableHeaders.find(el => el.attr === target)
      const updatedTableHeaders = [sortByObject]
      copiedTableHeaders.forEach(el => {
        if (el.attr !== target) {
          updatedTableHeaders.push({ ...el })
        }
      })
      this.tableHeaders = updatedTableHeaders
    },
    getSearchKeywordInputPlaceHolder() {
      let placeholder = this.searchByOptions.find(
        el => el.value === this.searchBy
      ).text
      return 'ค้นหา ' + placeholder
    },
    validateSelectedAccount(selectedAccount) {
      const errors = []

      const { accountTypeMessage, accountNumber } = selectedAccount

      if (accountTypeMessage === 'สรุปยอด') {
        errors.push('เป็นบัญชีสรุปยอด')
      }

      const forbiddenAccountNumbers = this.forbiddenLedgerAccountLinkers.map(
        el => el.glmaccn_accountNumber
      )
      if (forbiddenAccountNumbers.includes(accountNumber)) {
        errors.push('อยู่ในตัวเชื่อมโยงแยกประเภท')
      }

      return errors
    }
  }
}
</script>

<template>
  <b-modal
    v-model="displayModal"
    size="xl"
    scrollable
    title="เลือกบัญชี"
    header-bg-variant="primary"
    header-text-variant="light"
  >
    <b-table
      hover
      outlined
      bordered
      sticky-header="430px"
      :fields="tableHeaders"
      :items="tableContents"
      :busy="isLoading"
      thead-tr-class="text-center text-nowrap"
      tbody-tr-class="text-nowrap"
      selectable
      select-mode="single"
      @row-selected="rowSelectedHandler"
      @row-dblclicked="rowDoubleClickedHandler"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-2" variant="primary"></b-spinner>
          <strong>กำลังโหลดข้อมูล ...</strong>
        </div>
      </template>
    </b-table>
    <template #modal-footer>
      <!-- sortType / search -->
      <b-row class="w-100 d-flex justify-content-between">
        <b-col cols="12" lg="6" class="d-flex align-items-center">
          <div class="w-100 d-flex">
            <b-form-select
              class="mr-2"
              v-model="sortBy"
              :options="sortByOptions"
            ></b-form-select>
            <b-form-select
              v-model="sortType"
              :options="sortTypeOptions"
            ></b-form-select>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          class="d-flex justify-content-end align-items-center mt-3 mt-lg-0"
        >
          <span class="mr-3">ค้นหา</span>
          <div class="w-50">
            <b-form-input
              v-model="searchKeyword"
              :placeholder="getSearchKeywordInputPlaceHolder()"
            ></b-form-input>
          </div>
          <div class="w-25 pl-2">
            <b-form-select
              v-model="searchBy"
              :options="searchByOptions"
            ></b-form-select>
          </div>
        </b-col>
      </b-row>
      <!-- pagination / select button -->
      <b-row class="w-100">
        <b-col
          cols="12"
          lg="8"
          class="d-flex align-items-center justify-content-center justify-content-lg-start mt-2 mt-lg-0"
        >
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <b-button
                :variant="first ? 'primary' : 'secondary'"
                :disabled="!first"
                @click="paginationButtonClicked(first)"
                class="first-prev-style"
                size="sm"
              >
                แรกสุด
              </b-button>
            </div>
            <div>
              <b-button
                :variant="prev ? 'primary' : 'secondary'"
                :disabled="!prev"
                @click="paginationButtonClicked(prev)"
                class="second-prev-style"
                size="sm"
              >
                ก่อนหน้า
              </b-button>
            </div>
            <div v-if="page > 0" class="pagination-display-style">
              {{ page }}
            </div>
            <div>
              <b-button
                :variant="next ? 'primary' : 'secondary'"
                :disabled="!next"
                @click="paginationButtonClicked(next)"
                class="first-next-style"
                size="sm"
              >
                ถัดไป
              </b-button>
            </div>
            <div>
              <b-button
                :variant="last ? 'primary' : 'secondary'"
                :disabled="!last"
                @click="paginationButtonClicked(last)"
                class="second-next-style"
                size="sm"
              >
                ท้ายสุด
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          class="d-flex justify-content-center justify-content-lg-end mt-3 mt-lg-0"
        >
          <b-button
            :variant="rowSelected ? 'primary' : 'secondary'"
            style="width: 50%;"
            @click="selectedButtonClicked()"
            :disabled="!rowSelected"
          >
            เลือก
          </b-button>
        </b-col>
      </b-row>
    </template>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-modal>
</template>

<style scoped>
/* Pagination */
.first-prev-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.second-prev-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.first-next-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.second-next-style {
  background-color: transparent;
  border: 1px solid #dee2e6;
  color: black;
  padding: 5px 10px;
}
.pagination-display-style {
  border: 0px solid #dee2e6;
  height: 100%;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: not-allowed;
  min-width: 50px;
  text-align: center;
}
</style>
